.roadmap-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

.roadmap-title {
  text-align: center;
  margin-bottom: 10px !important;
  color: #ff9800;
  font-weight: 700 !important;
}

.roadmap-subtitle {
  text-align: center;
  margin-bottom: 60px !important;
  color: #aaa;
  font-style: italic;
}

.timeline-container {
  margin: 40px 0;
  padding: 20px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.timeline-date {
  color: #ff9800 !important;
  font-weight: 700 !important;
}

.timeline-paper {
  padding: 20px;
  border-radius: 8px !important;
  background: linear-gradient(145deg, #1e213a, #2a2d4a) !important;
  color: white !important; /* Forçando cor branca */
  transition: transform 0.3s ease;
}

.timeline-paper:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2) !important;
}

.timeline-milestone-title {
  color: #ff9800 !important;
  margin-bottom: 15px !important;
  font-weight: 600 !important;
}

.timeline-milestone-text {
  line-height: 1.6 !important;
  color: white !important; /* Garantindo que este texto específico seja branco */
}

.MuiTimelineDot-root {
  padding: 10px !important;
  margin: 10px 0 !important;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.3) !important;
}

.MuiTimelineConnector-root {
  background-image: linear-gradient(rgba(255, 152, 0, 0.8), rgba(255, 87, 34, 0.8)) !important;
  width: 3px !important;
}

.roadmap-footer {
  text-align: center;
  margin-top: 40px;
  padding: 20px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  font-style: italic;
}

/* Animações para os itens do timeline */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

.MuiTimelineItem-root {
  animation: fadeIn 0.6s ease-out forwards;
  opacity: 0;
}

.MuiTimelineItem-root:nth-child(1) { animation-delay: 0.1s; }
.MuiTimelineItem-root:nth-child(2) { animation-delay: 0.3s; }
.MuiTimelineItem-root:nth-child(3) { animation-delay: 0.5s; }
.MuiTimelineItem-root:nth-child(4) { animation-delay: 0.7s; }
.MuiTimelineItem-root:nth-child(5) { animation-delay: 0.9s; }

/* Garantindo que todo texto dentro dos cards seja branco */
.timeline-paper p, 
.timeline-paper span, 
.timeline-paper div,
.timeline-paper .MuiTypography-root {
  color: white !important;
}

/* Responsividade para dispositivos móveis */
@media (max-width: 768px) {
  .roadmap-title {
    font-size: 2rem !important;
  }
  
  .timeline-paper {
    padding: 15px;
  }
  
  .timeline-milestone-title {
    font-size: 1rem !important;
  }
  
  .timeline-date {
    font-size: 0.9rem !important;
  }
}