/* FILE: src/ProjectInfo.css */
.project-info {
  padding: 20px;
  color: #fafafa;
  background-color: #0a3dca;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.project-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.project-description, .project-details {
  font-size: 1.2rem;
  margin-bottom: 10px;
  text-align: center;
  max-width: 800px;
}