/* src/utils/Watermark.css */
.watermark {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.1; /* Ajuste a opacidade conforme necessário */
    z-index: 9999;
    pointer-events: none; /* Garante que a imagem não interfere com a interação do usuário */
    max-width: 80%;
    max-height: 80%;
  }