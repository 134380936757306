/* FILE: src/OpeningPage.css */
.opening-page {
  display: flex;
  flex-direction: column; /* Altera para coluna */
  align-items: flex-start; /* Alinha ao início */
  position: relative;
  /*overflow-y: auto; /* Permite rolagem vertical */
  min-height: 100vh;
  background-color: #0a3dca;
  color: white;
  overflow-x: hidden;
}

.content {
  text-align: center;
  position: relative;
  animation: slideDown 3s ease-out;
  width: 100%; /* Adiciona largura total */
  height: 100vh; /* Ocupa a altura total da tela */
}

.welcome-text {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  background: linear-gradient(45deg, #ffffff, #e0e0ff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: fadeIn 2s ease-out;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Centraliza o texto */
}

.welcome-subtext {
  font-size: 1.8rem;
  margin-bottom: 2rem;
  color: #ffffff;
}

.logo {
  width: 100px;
  height: auto;
  position: absolute;
  top: 20px; /* Posiciona no topo */
  left: 20px; /* Adiciona margem à esquerda */
}

.image-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Alinha ao início */
  transition: opacity 1s ease-in-out; /* Adiciona transição de opacidade */
  max-width: 800px;
  margin: 0 auto;
}

.fade-in {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.fade-out {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.image {
  width: auto;
  margin-right: 20px; /* Adiciona margem à direita */
  filter: contrast(1.2) brightness(0.9) saturate(1.5);
}

.hero-image {
  width: 100%;
  max-height: 500px;
  object-fit: contain;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.image-text-container {
  position: relative;
  margin-top: 1.5rem;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

.image-text {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
  color: white;
}

.dimensions-info {
  margin-top: 20px;
  font-size: 1rem;
  color: #fff;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fafafa;
  background-color: #0a3dca;
  padding: 20px;
  width: 100%; /* Ocupa a largura total */
  height: 100vh; /* Ocupa a altura total da tela */
  margin-bottom: 5rem;
  cursor: pointer;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  transition: transform 0.3s, background-color 0.3s;
  overflow: hidden;
}

.section:hover {
  transform: translateY(-5px);
  background-color: rgba(255, 255, 255, 0.15);
}

.section-content {
  display: flex;
  align-items: center;
  padding: 2rem;
}

.section-content.reverse {
  flex-direction: row-reverse;
}

.section-image {
  max-width: 100%;
  height: auto;
  width: 45%;
  max-width: 500px;
  border-radius: 15px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
}

.section-text {
  color: #fff;
  margin-top: 20px;
  flex: 1;
  padding: 0 2rem;
}

.section-text h2 {
  font-size: 2.2rem;
  margin-bottom: 1.5rem;
  color: #ffffff;
}

.section-text p {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.9);
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Hero Section */
.hero-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
  margin-top: 2rem;
  text-align: center;
}

.cta-button {
  background: linear-gradient(45deg, #ff4081 30%, #f50057 90%) !important;
  color: white !important;
  font-size: 1.2rem !important;
  font-weight: bold !important;
  padding: 0.8rem 2rem !important;
  border-radius: 30px !important;
  text-transform: none !important;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3) !important;
  transition: transform 0.3s, box-shadow 0.3s !important;
}

.cta-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4) !important;
}

/* Content Sections */
.section-container {
  width: 100%;
  max-width: 1400px;
  margin: 3rem auto;
  padding: 0 1rem;
}

.learn-more-button {
  margin-top: 1.5rem !important;
  background-color: transparent !important;
  border: 2px solid white !important;
  color: white !important;
  font-size: 1.1rem !important;
  padding: 0.5rem 1.5rem !important;
  border-radius: 25px !important;
  text-transform: none !important;
  transition: background-color 0.3s, color 0.3s !important;
}

.learn-more-button:hover {
  background-color: white !important;
  color: #0a3dca !important;
}

/* Footer */
.footer {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 2rem;
  text-align: center;
  margin-top: 3rem;
}

.footer p {
  margin-bottom: 1rem;
  font-size: 1rem;
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.footer-links a {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  transition: color 0.3s;
}

.footer-links a:hover {
  color: white;
  text-decoration: underline;
}

/* Responsividade */
@media (max-width: 1024px) {
  .welcome-text {
    font-size: 2.5rem;
  }
  
  .welcome-subtext {
    font-size: 1.5rem;
  }
  
  .section-text h2 {
    font-size: 2rem;
  }
}

@media (max-width: 768px) {
  .welcome-text {
    font-size: 2.2rem;
  }
  
  .image-text {
    font-size: 1.3rem;
  }
  
  .section-content {
    flex-direction: column;
    padding: 1.5rem;
  }
  
  .section-content.reverse {
    flex-direction: column;
  }
  
  .section-image {
    width: 100%;
    margin-bottom: 1.5rem;
  }
  
  .section-text {
    padding: 0;
  }
  
  .section-text h2 {
    font-size: 1.8rem;
  }
  
  .section-text p {
    font-size: 1.1rem;
  }
  
  .footer-links {
    flex-direction: column;
    gap: 1rem;
  }
}

@media (max-width: 480px) {
  .welcome-text {
    font-size: 1.8rem;
  }
  
  .welcome-subtext {
    font-size: 1.3rem;
  }
  
  .image-text {
    font-size: 1.1rem;
  }
  
  .cta-button {
    font-size: 1rem !important;
    padding: 0.6rem 1.5rem !important;
  }
  
  .section-text h2 {
    font-size: 1.5rem;
  }
  
  .section-text p {
    font-size: 1rem;
  }
}