.global-footer {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 1.5rem 0;
  width: 100%;
  color: white;
  margin-top: auto; /* Empurra para a parte inferior */
  position: relative;
  z-index: 10;
  bottom: 0; /* Garante que fique na parte inferior */
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.footer-left {
  margin-bottom: 0.5rem;
}

.footer-center {
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
  margin: 0.5rem 0;
}

.footer-link {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  transition: color 0.3s;
}

.footer-link:hover {
  color: white;
  text-decoration: underline;
}

.footer-right {
  display: flex;
  gap: 0.8rem;
}

.social-icon {
  color: white !important;
  background-color: rgba(255, 255, 255, 0.1) !important;
  transition: background-color 0.3s, transform 0.3s !important;
}

.social-icon:hover {
  background-color: rgba(255, 255, 255, 0.2) !important;
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    text-align: center;
  }
  
  .footer-center {
    justify-content: center;
    margin: 1rem 0;
  }
  
  .footer-right {
    margin-top: 0.5rem;
  }
}