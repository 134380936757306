/* FILE: src/styles.css */
body {
  font-family: sans-serif;
  margin: 0;
  color: #fafafa;
  background-color: #0a3dca;
}

#info {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 900;
  position: absolute;
  text-align: center;
  bottom: 0;
  color: #fafafa;
  background-color: #0a3dca;
  width: 100%;
  padding-bottom: 1rem;
  margin: 0;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}

#CursorEffect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}